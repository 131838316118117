import { connect } from 'react-redux';
import { setLattice } from '../actions/svg'

import CanvasMenu from '../components/CanvasMenu';

function mapStateToProps( state ){
    return {
        lattice: state.svg.lattice
    }
}

function mapDispatchToProps(dispatch){
    return{
        setLattice(size, range){
            dispatch(setLattice(size, range));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasMenu)
