const initailState = {
    task: '',
    tasks: ['sample']
};

export default function taskReducer(state = initailState, action){

    switch(action.type){
        case 'INPUT_TASK':
            return {    //この表記はSpread Attributesでstateと第二引数を結合して新しいstateを返している
                ...state,
                task: action.payload.task
            };
        case 'ADD_TASK':
            return {
                ...state,
                tasks: state.tasks.concat([action.payload.task])
            };
            

        default:
            return state;
    }
}