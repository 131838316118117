const initailState = {
    
    lattice: {
        size: 120,
        latticeRange: 3000,
    },
    selectedSymbols: [],
    contextMenu:{
        display: false
    },
    inputArea:{
        display: false
    },
    colorPicker: {
        display: false,
        defaultColor: '#999',
        changeColor: '#999',
        color: {
            r: '0',
            g: '9',
            b: '153',
            a: '1'
        }
    }
};

export default function svgReducer(state = initailState, action){

    console.log('dispatch',action)

    let newState;

    switch(action.type){
        
        case 'SET_LATTICE':

            newState = {
                ...state,
                lattice:{
                    size: action.payload.size,
                    // range: action.payload.range,
                }
            }

            return newState
            

        case 'SELECT_SYMBOLS':

            newState = {
                ...state,
                // selectedSymbols: state.selectedSymbols.concat(symbols),
                selectedSymbols: action.payload.symbols
            }

            // console.log('SELECT_SYMBOLS' , state);

            return newState
            
        case 'CLEAR_SYMBOLS':

            return {
                ...state,
                selectedSymbols: []
            }
            
        case 'SHOW_CONTEXTMENU':

            // console.log('SHOW_CONTEXTMENU' , action.payload, state);
            
            newState = {
                ...state,
                contextMenu:{
                    display: true,
                    clientX: action.payload.clientX,
                    clientY: action.payload.clientY,
                }
            }

            return newState
            
        case 'HIDE_CONTEXTMENU':

            // console.log('HIDE_CONTEXTMENU' , action.payload, state);

            return {
                ...state,
                contextMenu:{
                    display: false
                }
            }
            
        case 'SHOW_INPUTAREA':

            // console.log('SHOW_INPUTAREA' , action.payload, state);
            
            newState = {
                ...state,
                inputArea:{
                    display: true,
                    // X: action.payload.clientX,
                    // Y: action.payload.clientY,
                }
            }

            return newState
            
        case 'HIDE_INPUTAREA':

            console.log('HIDE_INPUTAREA' , action.payload, state);

            return {
                ...state,
                inputArea:{
                    display: false
                }
            }

        case 'SHOW_COLORPICKER':

            console.log('SHOW_COLORPICKER' , action.payload);

            return {
                ...state,
                colorPicker:{
                    display: true,
                    clientX: action.payload.clientX,
                    clientY: action.payload.clientY
                }
            }
            
        case 'HIDE_COLORPICKER':

            return {
                ...state,
                colorPicker:{
                    display: false
                }
            }


        default:
            return state;
    }
}