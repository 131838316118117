import React from 'react';
import { render } from 'react-dom'
import { BrowserRouter as Router, Route, Switch, Link, NavLink  } from 'react-router-dom'; 


export default function Header(props) {
    return (
        <div className="header">
            <Link to="/">Home</Link>
            <Link to="/Canvas">Canvas</Link>
            <Link to="Test">test</Link>
        </div>
    )
}


// export default class Header extends React.Component{
//     render(){
//         return (
//             <div className="header">
//                 <Router>
//                     <Link to="/">Home</Link>
//                     <Link to="Test">test</Link>
//                 </Router>
//             </div>
//         )
//     }
// }
