

import firebase from 'firebase/app'
import 'firebase/firestore' // <- needed if using firestore

import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

import taskReducer from './taskReducer';
import svgReducer from './svgReducer';
// import testReducer from './testReducer';


const rootReducer = combineReducers({
    task: taskReducer,
    svg: svgReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    // firestoretest: testReducer
})

export default rootReducer