import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'; 
import 'react-app-polyfill/ie11';

import reduxThunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux';

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import { ReactReduxFirebaseProvider, useFirestore, firebaseReducer  } from 'react-redux-firebase';
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'

import Header from './containers/Header';
// import Svg from './containers/Svg';
import CanvasWrapper from './containers/CanvasWrapper';
import Test from './components/Test';

import rootReducer from './reducers/rootReducer'


const firebaseConfig = {
    apiKey: "AIzaSyC3q1y8HCToSF0k0JgC6OVh1IElCNAxZes",
    authDomain: "canvas-81955.firebaseapp.com",
    databaseURL: "https://canvas-81955.firebaseio.com",
    projectId: "canvas-81955",
    storageBucket: "canvas-81955.appspot.com",
    messagingSenderId: "38449208473",
    appId: "1:38449208473:web:98e1643105f03fe9d0ec08",
    measurementId: "G-QP4R1RGN9V"
};


firebase.initializeApp(firebaseConfig)
firebase.firestore()


const initialState = {
};
const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(reduxThunk)
);


const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true,
    attachAuthIsReady: true,
    oneListenerPerPath: true
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}


const root = document.getElementById('app');

render(
    (
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <Router>
                    <Header />
                    
                    <div className='contents'>
                        <Switch>
                            {/* <Route exact path='/' component={Svg} /> */}
                            <Route exact path='/' component={CanvasWrapper} />
                            <Route path='/test' component={Test} />
                        </Switch>
                    </div>
                </Router>
            </ReactReduxFirebaseProvider>
        </Provider>
    
    )
,root)
