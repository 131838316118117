import React, { Component } from 'react';

import Symbol from '../containers/Symbol'

console.log('components/CanvasContents.js');

export default class CanvasWrapper extends Component {
  

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { firestore } = this.props
        // firestore.setListener({ collection: 'symbols' });
    }
  
    componentWillUnmount() {
        const firestore = this.props.firestore;
        // firestore.unsetListener({ collection: 'symbols' });
    }


    render(){
        

        let lattice = [];

        // let latticeSize = 120;
        let latticeSize = this.props.lattice.size
        let latticeRange = 3000;

        for(let i=1; i < latticeRange; i = i + latticeSize ){
            lattice.push(<line key={'yoko' + i} x1="0" y1={i} x2={latticeRange} y2={i} stroke="#DDDDDD" strokeWidth="1" />)
            lattice.push(<line key={'tate' + i} x1={i} y1="0" x2={i} y2={latticeRange} stroke="#DDDDDD" strokeWidth="1" />)
        }

        return(
            <React.Fragment>
                <div id="canvas-content">
                    <svg id="svg">

                        { lattice }

                        {
                            !!this.props.symbols && this.props.symbols.map(function(symbol, i){

                                return(
                                    <Symbol local_id={symbol.id} key={symbol.id} symbol={symbol} firestore={this.props.firestore} />
                                )
                            }.bind(this))
                        }
                        
                    </svg>
                    
                </div>
            </React.Fragment>
        )
    }   
}


