import { connect } from 'react-redux';
import ColorPicker from '../components/ColorPicker';

function mapStateToProps( state ){
    return {
        selectedSymbols: state.svg.selectedSymbols,
        colorPicker: state.svg.colorPicker
    }
}


export default connect(mapStateToProps)(ColorPicker)
