import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect, withFirestore, useFirestore } from 'react-redux-firebase'
import { withHandlers } from 'recompose'

import Symbol from '../components/Symbol';
import { setSelectSymbols, clearSelectSymbols, showContextMenu, hideContextMenu, showColorPicker, hideColorPicker } from '../actions/svg'

function mapStateToProps( state ){
    return {
        contextMenu: state.svg.contextMenu,
        lattice: state.svg.lattice
    };
}

function mapDispatchToProps(dispatch){
    return{
        showColorPicker(symbol){
            dispatch(showColorPicker(symbol));
        },
        hideColorPicker(){
            dispatch(hideColorPicker());
        },
        showContextMenu(symbol, clientX, clientY){
            dispatch(showContextMenu(symbol, clientX, clientY));
        },
        hideContextMenu(){
            dispatch(hideContextMenu());
        },
        setSelectSymbols(symbols){
            dispatch(setSelectSymbols(symbols));
        },
        clearSelectSymbols(){
            dispatch(clearSelectSymbols());
        },
    }
}



export default compose(
    // firestoreConnect(() => ['symbols']),
    connect(mapStateToProps, mapDispatchToProps)
)(Symbol)


