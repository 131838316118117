import React, { Component } from 'react';

console.log('components/CanvasMenu.js');



export default class CanvasMenu extends Component {
  

    constructor(props) {
        super(props);
        this.setLattice = this.setLattice.bind(this);
        this.templateSymbolClick = this.templateSymbolClick.bind(this);
    }

    componentDidMount() {
        const { firestore } = this.props
        // firestore.setListener({ collection: 'symbols' });
    }
  
    componentWillUnmount() {
        const firestore = this.props.firestore;
    }

    setLattice(e){
        //ログイン機能が出来たらFirestoreのプロファイルにパラメータを格納する
        
        console.log('setLattice')
        const _this = this;
        if (e.keyCode === 13) {
            // エンターキー押した時の処理
            let size = Number(e.target.value);
            _this.props.setLattice(size);
        }
    }
    
    templateSymbolClick(e){

        e.preventDefault();
        console.log('symbol click');

        const _this = this;

        // >>>>>>>> コピー用のレイヤー作成 >>>>>>>>

        var svgWork = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svgWork.setAttributeNS(null, 'version', '1.1')
        svgWork.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        svgWork.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    
        svgWork.setAttribute("width", '100%');
        svgWork.setAttribute("height", '100%');
        svgWork.style.setProperty( 'position' , 'fixed' );
        svgWork.style.setProperty( 'top' , 0 );
        svgWork.style.setProperty( 'left' , 0 );
        svgWork.style.setProperty( 'z-index' , 9999 );
        svgWork.style.setProperty( 'background-color' , '#FAFAFA' );
        svgWork.style.setProperty( 'opacity' , 0.9 );
        
        const symbolTemplate = e.currentTarget;
        const newSymbol = symbolTemplate.cloneNode(true);
        
        svgWork.appendChild(newSymbol);
        document.getElementById('canvas-wrapper').appendChild(svgWork);
        
        // <<<<<<<< コピー用のレイヤー作成 <<<<<<<<
        

        var svgWorkX = svgWork.getBoundingClientRect().left;    //コピー用SVG領域の座標
        var svgWorkY = svgWork.getBoundingClientRect().top;    //コピー用SVG領域の座標
        
        var SymbolRect = e.currentTarget.getBoundingClientRect();     //Symbolの座標
        var offsetX = e.clientX - SymbolRect.left + svgWorkX;
        var offsetY = e.clientY - SymbolRect.top + svgWorkY;

        let newX = e.clientX - offsetX;
        let newY = e.clientY - offsetY;
        let translate = "translate(" + newX + "," + newY + ")";
        newSymbol.setAttribute('transform', translate);
    
        var clicking = true;   //ドラッグ開始フラグ
    

        // >>>>>>>> ドラッグ中のイベント >>>>>>>>
        function onMouseMove(e){
    
            if (clicking == true) {
                let newX = e.clientX - offsetX;
                let newY = e.clientY - offsetY;
    
                let translate = "translate(" + newX + "," + newY + ")";
                newSymbol.setAttribute('transform', translate);
            }
        }
    
        document.addEventListener('mousemove', onMouseMove )
    
    
        // >>>>>>>> ドラッグ終了時のイベント >>>>>>>>
        document.onmouseup = function(e) {
            if(!clicking) return;
            clicking = false;   //ドラッグ中フラグ

            var svgX = document.getElementById('svg').getBoundingClientRect().left;
            var svgY = document.getElementById('svg').getBoundingClientRect().top;
            
            var rect = newSymbol.getBoundingClientRect();
            var offsetX = e.clientX - rect.left + svgX;
            var offsetY = e.clientY - rect.top + svgY;
    
            let newX = e.clientX - offsetX;
            let newY = e.clientY - offsetY;
            
            if(newX > 0 && newY > 0) {

                let translate = "translate(" + newX + "," + newY + ")";
                newSymbol.setAttribute('transform', translate);
                
                const shape = newSymbol.children[0];
                const text = newSymbol.children[1];

                const postSymbol = {
                    transform: translate,
                    shape: {
                        tagName: shape.tagName, 
                        width: shape.getAttribute('width'),
                        height: shape.getAttribute('height'),
                        rx: shape.getAttribute('rx'),
                        cx: shape.getAttribute('cx'),
                        cy: shape.getAttribute('cy'),
                        r: shape.getAttribute('r'),
                        fill: shape.getAttribute('fill'),
                        stroke: shape.getAttribute('stroke'),
                        stroke_width: shape.getAttribute('stroke-width'),
                    },
                    text: {
                        x: text.getAttribute('x'),
                        y: text.getAttribute('y'),
                        fill: text.getAttribute('fill'),
                        text_anchor: text.getAttribute('text-anchor'),
                        dominat_baseline: text.getAttribute('dominat-baseline'),
                        value: text.innerHTML
                    }
                };
                
                document.removeEventListener('mousemove', onMouseMove);
                

                _this.props.firestore.collection('symbols').doc().set(postSymbol)
                .then(function() {
                    console.log("保存成功: シンボル追加");
                })
                .catch(function(error) {
                    console.error("保存失敗: ", error);
                });
            }
    
            svgWork.removeChild(newSymbol);
            svgWork.parentNode.removeChild(svgWork);
    
        }.bind(this);
    
    };

    render(){
        
        const cm = this.props.contextMenu;
        const cp = this.props.colorPicker;
        const ia = this.props.inputArea;

        
        


        return(
            <React.Fragment>


                <div id="menu-wrapper">

                    <div id="menu-header">
                        <label id="menu-open" htmlFor="menu-checkbox"><span></span></label>
                        <label id="menu-close" htmlFor="menu-checkbox"></label>
                    </div>
                    
                    <br/>
                    グリッド線：<input id="grid-size" type="text" size="2" defaultValue={this.props.lattice.size} onKeyDown={e => this.setLattice(e)}  />px
                    <br/>
                    <br/>
                    <input id="grid-fix" type="checkbox" /><font size="2">グリッド線に合わせて図形を配置</font>

                    <hr/>
                    
                    {/* ここに中身を入れる
                    <button onClick={(e) => e.currentTarget.addSymbol()}>追加する</button>  
                    <hr/>
                    ・右クリック実装する<br />
                    ・スライド幅増やして要素が真ん中くらいになるように<br />
                    ・最大画面とか縮小画面とかのスクロールとかどうするか<br />
                    <hr/> */}

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <svg width="124px">
                                        <g id="shikaku" transform="translate(12,5)" onMouseDown={this.templateSymbolClick} >
                                            <rect width="100" height="100" rx="10" fill="yellowgreen" stroke="green" strokeWidth="3" />
                                            <text x="50" y="50" fill="blue" textAnchor="middle" dominantBaseline="central">四角</text>
                                        </g>
                                    </svg>
                                </td>
                                <td>
                                    <svg width="124px">
                                        <g id="maru" transform="translate(12,5)" onMouseDown={this.templateSymbolClick} >
                                            <circle cx="50" cy="50" r="50" fill="pink" stroke="red" strokeWidth="3" />
                                            <text x="50" y="50" fill="blue" textAnchor="middle" dominantBaseline="central">丸</text>
                                        </g>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td>aa</td>
                                <td>bb</td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </React.Fragment>
        )
    }   
}


