import React, { Component } from 'react';
// import 'react-app-polyfill/ie11';

import CanvasMenu from '../containers/CanvasMenu'
import CanvasContents from '../containers/CanvasContents'
import CanvasRibbon from '../containers/CanvasRibbon'

import ContextMenu from '../containers/ContextMenu'
import ColorPicker from '../containers/ColorPicker'
import InputArea from '../containers/InputArea'

console.log('components/CanvasWrapper.js');

export default class CanvasWrapper extends Component {
  

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { firestore } = this.props
        firestore.setListener({ collection: 'symbols' });
    }
  
    componentWillUnmount() {
        const firestore = this.props.firestore;
        firestore.unsetListener({ collection: 'symbols' });
    }

    render(){
        
        const cm = this.props.contextMenu;
        const cp = this.props.colorPicker;
        const ia = this.props.inputArea;

        return(
            <React.Fragment>
                
                <input id="menu-checkbox" type="checkbox" />

                <div id="canvas-wrapper">
                    <CanvasRibbon firestore={this.props.firestore} />
                    <CanvasMenu firestore={this.props.firestore} />
                    <CanvasContents firestore={this.props.firestore} />
                </div>
                
                { cm.display ? <ContextMenu /> : null }
                { ia.display ? <InputArea firestore={this.props.firestore} /> : null }
                { cp.display ? <ColorPicker firestore={this.props.firestore} /> : null }

            </React.Fragment>
        )
    }   
}


