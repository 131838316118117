import React from 'react';
import { SketchPicker, TwitterPicker, CirclePicker } from 'react-color';

export default class ColorPicker extends React.Component {

  constructor(props){
    super(props);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.divMouseDown = this.divMouseDown.bind(this);
  }

  divMouseDown(e){
    e.stopPropagation();
  }

  handleChangeComplete(color, e){
    e.stopPropagation();
    console.log('ColorPicker Change Color');

    const _this = this;
    const symbols = _this.props.selectedSymbols;

    Array.prototype.forEach.call(symbols, function(symbol) {
      var docRef = _this.props.firestore.collection("symbols").doc(symbol.id);
      console.log(docRef);

      const payload = {
          shape: {
              fill: color.hex
          }
      }
      docRef.set(payload, { merge:true })
      .then(function() {
          console.log("保存成功", symbol.id );
      })
      .catch(function(error) {
          console.error("保存失敗: ", error);
      });

    })

  };


  render() {
    console.log('contextMenu Render',this.props.contextMenu)
    const cp = this.props.colorPicker;

    const cpstyle = {
        position: 'absolute',
        left: cp.clientX + 'px',
        top: cp.clientY + 'px',
        zIndex: 9999
    }

    return (
      <div className='colorPicker' style={ cpstyle } onMouseDown={this.divMouseDown } >
        <CirclePicker onChange={ this.handleChangeComplete }/>
      </div>
    );
  }
  
}