
console.log('actions/svg.js')

export const setSvg = (svgX,svgY) => ({
    type: 'SET_SVG',
    payload: {
        'svgX': svgX,        
        'svgY': svgY
    }
});




export const setLattice = (size, range) => ({
    type: 'SET_LATTICE',
    payload: {
        size,
        range
    }
});


export const setSelectSymbols = (symbols) => ({
    type: 'SELECT_SYMBOLS',
    payload: {
        symbols
    }
});

export const clearSelectSymbols = () => ({
    type: 'CLEAR_SYMBOLS'
});


export const showContextMenu = (symbol, clientX, clientY) => ({
    type: 'SHOW_CONTEXTMENU',
    payload: {
        symbol,
        clientX,
        clientY
    }
});

export const hideContextMenu = () => ({
    type: 'HIDE_CONTEXTMENU'
});

export const showInputArea = (symbol, clientX, clientY) => ({
    type: 'SHOW_INPUTAREA',
    payload: {
        symbol,
        clientX,
        clientY
    }
});

export const hideInputArea = () => ({
    type: 'HIDE_INPUTAREA'
});

export const showColorPicker = (symbol, clientX, clientY) => ({
    type: 'SHOW_COLORPICKER',
    payload: {
        symbol,
        clientX,
        clientY
    }
});

export const hideColorPicker = () => ({
    type: 'HIDE_COLORPICKER'
});