import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect, withFirestore, useFirestore } from 'react-redux-firebase'
import CanvasWrapper from '../components/CanvasWrapper';

console.log('containers/CanvasWrapper.js');

function mapStateToProps( state ){
    return {
        symbols: state.firestore.ordered.symbols,
        contextMenu: state.svg.contextMenu,
        colorPicker: state.svg.colorPicker,
        inputArea: state.svg.inputArea
    }
}

function mapDispatchToProps(dispatch){
    return{
    }
}
export default compose(
    // withFirestore,
    firestoreConnect(['symbol']),
    // firestoreConnect(() => ['symbols']),
    connect(mapStateToProps, mapDispatchToProps)
)(CanvasWrapper)