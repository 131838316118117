import React from 'react';

export default class ContextMenu extends React.Component {

    constructor(props){
        super(props);
        console.log('constructor')
        
        this.showInputArea = this.showInputArea.bind(this);
        this.hideInputArea = this.hideInputArea.bind(this);
        this.showColorPicker = this.showColorPicker.bind(this);
        this.hideColorPicker = this.hideColorPicker.bind(this);

    }

    showInputArea(e){
        console.log('showInputArea'); 
        e.stopPropagation();
        
        const _this = this;
        let symbol = e.currentTarget;     //!!!!!!!!!複数選択じゃない方がいいかも

        _this.props.showInputArea(symbol, e.clientX, e.clientY);       //コンポーネントを表示
        window.addEventListener('mousedown', _this.hideInputArea, false );       //コンポーネントを非表示にするリスナーを登録

    }

    hideInputArea(e){
        console.log('hideInputArea'); 

        if(e.target.tagName == 'TEXTAREA') return

        const _this = this;
        _this.props.hideInputArea();                                    //コンポーネントを非表示
        window.removeEventListener('mousedown', _this.hideInputArea, false);     //コンポーネントを非表示にするリスナーを削除
    }

    showColorPicker(e){
        console.log('showColorPicker'); 
        e.stopPropagation();

        const _this = this;
        let symbols = document.querySelectorAll('.symbolSelected');

        _this.props.showColorPicker(symbols, e.clientX, e.clientY);     //コンポーネントを表示
        window.addEventListener('mousedown', _this.hideColorPicker, false );     //コンポーネントを非表示にするリスナーを登録

    }

    hideColorPicker(e){
        console.log('hideColorPicker'); 

        const _this = this;
        _this.props.hideColorPicker();                                  //コンポーネントを非表示
        window.removeEventListener('mousedown', _this.hideColorPicker, false);   //コンポーネントを非表示にするリスナーを削除
    }

    render() {
        console.log('contextMenu Render',this.props.contextMenu)
        
        const sb = this.props.selectedSymbols;
        const cm = this.props.contextMenu;


        const cmstyle = {
            left: cm.clientX + 'px',
            top: cm.clientY + 'px',
            zIndex: 9999
        }

        return (
            <div className='contextmenu' style={cmstyle}>
                <ul>
                    { sb.length !== 1 ? null : /* 複数選択の時には表示しない */
                        <li onMouseDown={this.showInputArea}>
                        文字変更
                        </li>
                    }
                    <li onMouseDown={this.showColorPicker}>
                        色変更
                    </li>
                    <li>
                        詳細表示
                    </li>
                </ul>
            </div>
        );
    }
  
}