import { connect } from 'react-redux';
import { setLattice } from '../actions/svg'

import CanvasRibbon from '../components/CanvasRibbon';

function mapStateToProps( state ){
    return {
    }
}

function mapDispatchToProps(dispatch){
    return{
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasRibbon)
