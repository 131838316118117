import React from 'react';

export default class InputArea extends React.Component {

  constructor(props){
    super(props);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.hideInputArea = this.hideInputArea.bind(this);
  }

  onKeyPress(e){
    e.stopPropagation();
    console.log(e)
    if ( e.key === 'Enter' && e.shiftKey === false) {
      this.hideInputArea();
    }
  }

  onChange(e){
    e.stopPropagation();
    console.log(e)
  }

  onmousedown(e){
    e.stopPropagation();
    console.log(e)
  }

  hideInputArea(){
        
    const _this = this;

    let inputArias = document.querySelectorAll('.inputAria');

    Array.prototype.forEach.call(inputArias, function(inputAria) {

        const iaid = inputAria.getAttribute('id');
        const symbolid = iaid.replace('ia-','')

        var docRef = _this.props.firestore.collection("symbols").doc(symbolid);

        const payload = {
            text: {
                value: inputAria.value
            }
        }
        docRef.set(payload, { merge:true })
        .then(function() {
            console.log("保存成功", symbolid );
        })
        .catch(function(error) {
            console.error("保存失敗: ", error);
        });

        _this.props.hideInputArea();
    })
  }


  render() {

    console.log('inputAria Render',this.props.inputAria)

    const symbol = this.props.selectedSymbols[0];
    if (!symbol) {return (<div></div>)}
    const X = symbol.getBoundingClientRect().left + (symbol.getBoundingClientRect().width / 2)
    const Y = symbol.getBoundingClientRect().top + (symbol.getBoundingClientRect().height / 2)

    const shape = symbol.children[0];
    const text = symbol.children[1];

    const ia = this.props.inputAria;

    const iastyle = {
        position: 'fixed',
        left: X + 'px',
        top: Y + 'px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999
    }

    return (
      <textarea id={symbol.id} className='inputAria' style={iastyle} defaultValue={text.innerHTML} onMouseDown={this.onmousedown} onChange={this.onChange} onKeyPress={this.onKeyPress}></textarea>
    );
  }
  
}