import { connect } from 'react-redux';
import CanvasContents from '../components/CanvasContents';

console.log('containers/CanvasContents.js');

function mapStateToProps( state ){
    return {
        symbols: state.firestore.ordered.symbols,
        selectedSymbols: state.svg.selectedSymbols,
        lattice: state.svg.lattice
    }
}

function mapDispatchToProps(dispatch){
    return{
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasContents)