import { connect } from 'react-redux';
import ContextMenu from '../components/ContextMenu';

import { showInputArea, hideInputArea, showColorPicker, hideColorPicker } from '../actions/svg'


function mapStateToProps( state ){
    return {
        // symbols: state.firestore.ordered.symbols,
        selectedSymbols: state.svg.selectedSymbols,
        contextMenu: state.svg.contextMenu,
        // colorPicker: state.svg.colorPicker
    }
}

function mapDispatchToProps(dispatch){
    return{
        showInputArea(symbol, x, y){
            dispatch(showInputArea(symbol, x, y));
        },
        hideInputArea(){
            dispatch(hideInputArea());
        },
        showColorPicker(symbols, x, y){
            dispatch(showColorPicker(symbols, x, y));
        },
        hideColorPicker(){
            dispatch(hideColorPicker());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu)
