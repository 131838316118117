import React, { useState, useEffect } from 'react';


export default class Symbol extends React.Component{

    constructor(props) {
        super(props);

        this.showColoPicker = this.showColoPicker.bind(this);
        this.symbolMouseDown = this.symbolMouseDown.bind(this);
        this.dragSymbols = this.dragSymbols.bind(this);
        this.showInputAria = this.showInputAria.bind(this);
        this.hideInputAria = this.hideInputAria.bind(this);
        this.symbolDbClick = this.symbolDbClick.bind(this);

        this.selectSymbol = this.selectSymbol.bind(this);
        this.deselectSymbols = this.deselectSymbols.bind(this);

        this.hideContextMenu = this.hideContextMenu.bind(this);
        this.showContextMenu = this.showContextMenu.bind(this);
    }

    componentDidMount(){
        
        const _this = this;

        document.onkeydown = function(e) {

            if (e.keyCode == 46) {  //del key
                let symbols = document.querySelectorAll('.symbolSelected');
                _this.rmSymbols(symbols);
            }
        
        };
    }

    showColoPicker() {
        state.setState({ displayColorPicker: true })
    };
    
    hideColoPicker() {
        state.setState({ displayColorPicker: true })
    };

    rmSymbols(symbols){

        console.log('rmSymbols')

        const firestore = this.props.firestore;
        Array.prototype.forEach.call(symbols, function(symbol) {
            firestore.collection("symbols").doc(symbol.id).delete();
        })

    }

    selectSymbol(e){
        console.log('selectSymbol')
        e.preventDefault();
        
        const _this = this;
        const currentSymbol = e.currentTarget;
        
         //シフトキーを押したままシンボルを選択した場合には、選択済みシンボルを選択解除しない
        if( !e.shiftKey && !currentSymbol.classList.contains('symbolSelected') ){
            _this.deselectSymbols(e);
        }

        currentSymbol.setAttribute('class', 'symbolSelected');

        const symbols = document.querySelectorAll('.symbolSelected');
        _this.props.setSelectSymbols(symbols);

        _this.dragSymbols(e);
        
        window.addEventListener('mousedown', _this.deselectSymbols, false );     //リスナーを登録
        window.addEventListener('touchstart', _this.deselectSymbols, false );     //リスナーを登録

    }

    deselectSymbols(e){
        console.log('deselectSymbols')

        const _this = this;
        
        let symbols = document.querySelectorAll('.symbolSelected');

        Array.prototype.forEach.call(symbols, function(symbol) {
            symbol.classList.remove('symbolSelected');
        })

        _this.props.setSelectSymbols([]);
        
        window.removeEventListener('mousedown', _this.deselectSymbols, false);   //リスナーを削除
        window.removeEventListener('touchstart', _this.deselectSymbols, false);   //リスナーを削除
        
    }

    dragSymbols(e){

        console.log('dragSymbols')

        e.stopPropagation();

        const _this = this;

        const svgEl = document.getElementById('svg');
        const svgX = svgEl.getBoundingClientRect().left;
        const svgY = svgEl.getBoundingClientRect().top;

        let symbols = document.querySelectorAll('.symbolSelected');

        let clicking = true;   //ドラッグ開始
        let draging = false;


        Array.prototype.forEach.call(symbols, function(symbol) {

            const shape = symbol.children[0];
            const shapeRect = shape.getBoundingClientRect();

            
            let offsetX, offsetY;
            if(!e.clientX) {
                offsetX = e.touches[0].clientX - shapeRect.left + svgX;
                offsetY = e.touches[0].clientY - shapeRect.top + svgY;
            } else {
                offsetX = e.clientX - shapeRect.left + svgX;
                offsetY = e.clientY - shapeRect.top + svgY;
            }


            window.addEventListener('mousemove', function(e){
                onMouseMove(e, symbol, offsetX, offsetY);
            }.bind(this));

            window.addEventListener('mouseup', function(e){
                onMouseUp(e, symbols)
            }.bind(this));

            window.addEventListener('touchmove', function(e){
                onMouseMove(e, symbol, offsetX, offsetY);
            }.bind(this),  { passive: false });

            window.addEventListener('touchend', function(e){
                onMouseUp(e, symbols)
            }.bind(this),  { passive: false });

        }.bind(this));


        function onMouseMove(e, symbol, offsetX, offsetY){

            if(!clicking) return;
            draging = true;

            e.preventDefault();

            let newX, newY;
            if(!e.clientX) {
                newX = e.touches[0].clientX - offsetX;
                newY = e.touches[0].clientY - offsetY;
            } else {
                newX = e.clientX - offsetX;
                newY = e.clientY - offsetY;
            }

            console.log('drag',symbol.id, newX, newY)

            let translate = "translate(" + newX + "," + newY + ")";
            symbol.setAttribute('transform', translate);
        }

        
        function onMouseUp(e, symbols) {

            if(!clicking) return;
            clicking = false;   //ドラッグ終了

            if(!draging) return;
            draging = false;
            
            console.log('onMouseUp')


            Array.prototype.forEach.call(symbols, function(symbol) {

                const shape = symbol.children[0];
                const text = symbol.children[1];

                const shapeRect = shape.getBoundingClientRect();

                const gridSize = _this.props.lattice.size;
                let offsetX, offsetY, newX, newY;

                if(!e.clientX) {
                    offsetX = e.changedTouches[0].clientX - shapeRect.left + svgX;
                    offsetY = e.changedTouches[0].clientY - shapeRect.top + svgY;
                    newX = e.changedTouches[0].clientX - offsetX;
                    newY = e.changedTouches[0].clientY - offsetY;
                } else {
                    offsetX = e.clientX - shapeRect.left + svgX;
                    offsetY = e.clientY - shapeRect.top + svgY;
                    newX = e.clientX - offsetX;
                    newY = e.clientY - offsetY;
                }
                
                let shapeWidth,shapeHeight

                switch (shape.tagName) {
                    case 'rect':
                        shapeWidth = Number(shape.width.animVal.value);
                        shapeHeight = Number(shape.height.animVal.value);
                        break;

                    case 'circle':
                        shapeWidth = Number(shape.r.animVal.value) * 2; //半径なので２倍
                        shapeHeight = Number(shape.r.animVal.value) * 2; //半径なので２倍
                        break;
                    
                    default:
                        break;
                }


                newX = (newX - newX % gridSize) + ( (gridSize - shapeWidth) / 2)
                newY = (newY - newY % gridSize) + ( (gridSize - shapeHeight) / 2)


                const translate = "translate(" + newX + "," + newY + ")";
                symbol.setAttribute('transform', translate);

                // let translate = symbol.getAttribute('transform');



                const docRef = _this.props.firestore.collection("symbols").doc(symbol.id);
                
                const payload = {
                    transform: translate,
                }
       
                docRef.set(payload, { merge:true })
                .then(function() {
                    console.log("保存成功", symbol.id );
                })
                .catch(function(error) {
                    console.error("保存失敗: ", error);
                });
                
            }.bind(this));

            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);

        }
    }

    
    showInputAria(symbol){

        // const _this = this;

        // const shape = symbol.children[0];
        // const text = symbol.children[1];
        // console.log(symbol)

        // const X = symbol.getBoundingClientRect().left + (symbol.getBoundingClientRect().width / 2)
        // const Y = symbol.getBoundingClientRect().top + (symbol.getBoundingClientRect().height / 2)

        // const inputAria = document.createElement("textarea");
        // inputAria.classList.add("inputAria")
        // inputAria.style.position = "fixed";
        // inputAria.style.left = X + "px";
        // inputAria.style.top = Y + "px";
        // inputAria.style.transform = "translate(-50%, -50%)";
        // inputAria.value = text.innerHTML
        // inputAria.setAttribute('symbolID',symbol.id);
        // console.log(text)

        // inputAria.addEventListener('keypress', function(e){
        //     if ( e.keyCode === 13 && e.shiftKey === false) {
        //         _this.hideInputAria();
        //       }
        // });

        // document.body.appendChild(inputAria);
    }

    hideInputAria(){
        
        const _this = this;

        let inputArias = document.querySelectorAll('.inputAria');

        Array.prototype.forEach.call(inputArias, function(inputAria) {

            const symbolid = inputAria.getAttribute('symbolid');
            var docRef = _this.props.firestore.collection("symbols").doc(symbolid);

            const payload = {
                text: {
                    value: inputAria.value
                }
            }
            docRef.set(payload, { merge:true })
            .then(function() {
                console.log("保存成功", symbolid );
            })
            .catch(function(error) {
                console.error("保存失敗: ", error);
            });

            inputAria.parentNode.removeChild(inputAria);
        })
    }

    setText(symbols){
        
    }
    

    showContextMenu(e){
        console.log('showContextMenu');
        e.preventDefault();
        // e.stopPropagation();
        
        const _this = this;
        document.addEventListener('click', _this.hideContextMenu );

        const symbol = e.currentTarget;
        _this.props.showContextMenu(symbol, e.clientX, e.clientY);

    }

    hideContextMenu(){
        
        console.log('hideContextMenu'); 

        const _this = this;
        _this.props.hideContextMenu();
        document.removeEventListener('click', _this.hideContextMenu);

    }


    symbolMouseDown(e){
        console.log('symbolMouseDown')

        // e.stopImmediatePropagation();
        e.stopPropagation();

        this.selectSymbol(e);

    }

    symbolClick(e){
        console.log('symbolClick')
    }

    symbolDbClick(e){
        const symbol = e.currentTarget;
        this.showInputAria(symbol);
    }

    render(){

        const shape = this.props.symbol.shape;
        const text = this.props.symbol.text;

        console.log(shape)
        switch (shape.tagName) {
            case 'rect':
                return (
                    <g id={this.props.local_id} key={this.props.local_id} transform={this.props.symbol.transform} onClick={this.symbolClick} onMouseDown={this.symbolMouseDown} onTouchStart={this.symbolMouseDown} onDoubleClick={this.symbolDbClick} onContextMenu={this.showContextMenu}>
                        <rect width={shape.width} height={shape.height} rx={shape.rx} fill={shape.fill} stroke={shape.stroke} strokeWidth={shape.stroke_Width} />
                        <text x={shape.width /2} y={shape.height / 2} fill="blue" textAnchor="middle" dominantBaseline="central">{text.value}</text>
                    </g>
                )
            case 'circle':
                return (
                    <g id={this.props.local_id} key={this.props.local_id} transform={this.props.symbol.transform} onClick={this.symbolClick} onMouseDown={this.symbolMouseDown} onTouchStart={this.symbolMouseDown} onDoubleClick={this.symbolDbClick} onContextMenu={this.showContextMenu}>
                        <circle cx={shape.cx} cy={shape.cy} r={shape.r} fill={shape.fill} stroke={shape.stroke} strokeWidth={shape.stroke_Width} />
                        <text x={shape.cx} y={shape.cy} fill="blue" textAnchor="middle" dominantBaseline="central">{text.value}</text>
                    </g>
                )
            default:
                break;
        }

    }
}








