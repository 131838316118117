import { connect } from 'react-redux';
import InputArea from '../components/InputArea';

import { hideInputArea } from '../actions/svg'

function mapStateToProps( state ){
    return {
        // symbols: state.firestore.ordered.symbols,
        selectedSymbols: state.svg.selectedSymbols,
        // contextMenu: state.svg.contextMenu,
        // colorPicker: state.svg.colorPicker,
        inputArea: state.svg.inputArea
    }
}


function mapDispatchToProps(dispatch){
    return{
        hideInputArea(){
            dispatch(hideInputArea());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InputArea)

