import React, { Component } from 'react';

console.log('components/CanvasRibbon.js');



export default class CanvasRibbon extends Component {
  

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }
  
    componentWillUnmount() {
    }

    render(){
        
        return(
            <React.Fragment>

                <div id="ribbon-wrapper">
                    <fieldset style={{marginLeft: '300px', padding: '10px', border:'1px solid #ff0000'}}>
                        <input type="textbox" />
                        <br/>
                    </fieldset>

                </div>

            </React.Fragment>
        )
    }   
}


