import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'


console.log('components/Test.js');



class Test extends Component {
  

    constructor(props) {
      super(props);
      this.addText = this.addText.bind(this);
    }

    componentDidMount() {
      console.log(this)
      const firestore = this.props.firestore;
      firestore.setListener({ collection: 'test' }) // or object notation
  
      console.log('aa');

    }
  
    componentWillUnmount() {
    }


    addText(){
      this.props.firestore.collection('test').add({name:'aaafff'})

    }

    render(){
          return (
      <div>
        <button onClick={this.addText}>追加</button>
        {
          !!this.props.test && this.props.test.map(function(test, i){
              return(
                  <li local_id={test.id} key={test.id} >{test.name}</li>
              )
          })
        }
      </div>
    )
  }
}


function mapStateToProps( state ){
  // return state;
  console.log(state.firestore.ordered.test)
  
  return {
    test: state.firestore.ordered.test
  }
}


export default compose(


  firestoreConnect(() => ['symbols']), // or { collection: 'todos' }
  connect((state, props) => (mapStateToProps)
  )
 )(Test)